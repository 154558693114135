.disclaimer {
  position: relative;
  padding-bottom: 50px;
@include media("<=810px") {
  
}
  @include media("<=430px") {
      padding-bottom: 40px;
  }
  .section__title {
    text-align: left;
    color: #EBFF00;
    @include media("<=810px") {
     text-align: center;
    }
  }
}

.disclaimer__inner {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
    overflow: hidden;
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    
  }
 
}



.disclaimer__text {
  max-width: 1016px;
  line-height: 1.2;
  font-size: 24px;
  font-weight: 500;
  text-align: justify;
  width: 100%;
  @include media("<=1280px") {
    font-size: 20px;
  }
    @include media("<=1080px") {
      font-size: 18px;
      
    }
     @include media("<=430px") {
    
     }
     span {
      font-size: 40px;
      font-weight: 900;
     }
}


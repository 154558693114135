.features {
  position: relative;
  width: 100%;
  padding-top: 66px;
padding-bottom: 258px;
  overflow: hidden;
  @include media("<=1080px") {
    padding-bottom: 230px;
  }
  @include media("<=810px") {
    padding-bottom: 100px;
  }
  @include media("<=430px") {
  }
  
}

.features__subtitle {
      max-width: 944px;
      margin: 0 auto;
      text-transform: uppercase;
      padding: 0 0 34px;
      text-align: center;
      font-size: 32px;
      font-weight: 900;
      color: #432F92;
      @include media("<=810px") {
        font-size: 28px;
      }
        @include media("<=430px") {
          font-size: 24px;
        }
}

.features__item {
  max-width: 330px;
  height: 112px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 900;
  padding: 5px;
  flex: 0 1 32%;
  text-transform: uppercase;
  @include media("<=1080px") {
   flex: 0 1 31%;
  }
    @include media("<=810px") {
      width: 100%;
      flex: 0 1 auto;
      font-size: 18px;
    }
  @include media("<=430px") {
   font-size: 16px;
  }
  p {
    max-width: 234px;
  }
}

.features__slider {
  position: relative;
  margin: 0 auto;
  z-index: 3;
  max-width: 1044px;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 81px;
  @include media("<=1280px") {}

  @include media("<=1024px") {}

  @include media("<=810px") {
    padding-top: 40px;
    
  }

  @include media("<=430px") {
    
  }

  @include media("<=320px") {}

  .slick-list {

    padding: 0 !important;

    // margin: 0 -250px;
    @include media("<=810px") {
      padding: 0 12vw !important;
    }

    @include media("<=430px") {
     padding: 0 8vw !important;

    }
  }

  .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
      column-gap: 27px;
      row-gap: 36px;
      flex-wrap: wrap;
    @include media("<=810px") {
      flex-wrap: nowrap;
      justify-content: center;
      column-gap: 30px;
    }
        @include media("<=430px") {
          column-gap: 20px;
        }
  }
  

  .slick-slide {
    position: relative;
    cursor: pointer;
    transition: all 0.3s linear;
    border-radius: 15px;
      background: #35008C;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  

}

.features__pic {
  position: absolute;
  top: 0;
  left: 0;
  @include media("<=810px") {
    left: -5%;
  }
}






.about {
  width: 100%;
  overflow-x: clip;
  position: relative;
  flex: 0 1 100%;
  height: 388px;
  background: #35008C;
  padding: 0 15px;
  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
   min-height: 388px;
  }
    @include media("<=430px") {
      min-height: 320px;
    }
  .section__title {
    text-align: left;
  }
}

.about__inner {
  height: 100%;
  padding-top: 65px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  z-index: 6;
  padding-right: 9.8vw;
  @include media("<=1280px") {
    padding-right: 0;
    justify-content: center;
  }
  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    padding-top: 40px;
    
  }
   
}

.about__content {
  max-width: 508px;
}

.about__text {
  font-size: 20px;
  font-size: 500;
  z-index: 2;
  line-height: 1.1;
  width: 100%;
  text-align: justify;
  padding-top: 65px;
  @include media("<=1280px") {
    padding-top: 60px;
  }
    @include media("<=1080px") {
      font-size: 18px;
      padding-top: 40px;
    }
  @include media("<=810px") {
    flex: 0 1 100%;
    
  }
  @include media("<=430px") {
    width: 100%;
    font-size: 16px;
  }
}

.about__image {
  flex: 0 1 100%;
  max-width: 1539px;
  width: 110%;
  @include media("<=810px") {
   
  }
    @include media("<=430px") {
      width: 120%;
    }
  img {
width: 100%;
    display: block;
  }
  
}








.gallery {
    width: 100%;
    overflow-x: clip;
    position: relative;
    background: #347F82;
    @include media("<=1280px") {
       
    }

    @include media("<=1080px") {
       margin: 0 auto;
    }

    @include media("<=810px") {
        
    }

    @include media("<=430px") {
       
    }
  
}

.gallery__slider {
    position: relative;
    margin: 0 auto;
    width: 100%;
    z-index: 3;
    overflow: hidden;
    padding: 99px 0;
    @include media("<=1280px") {
        
    }

    @include media("<=1080px") {}

    @include media("<=810px") {
        padding: 80px 0;
    }

    @include media("<=430px") {
        padding: 70px 0;
    }

    @include media("<=320px") {}

    .slick-slide {
        position: relative;
        cursor: pointer;
        transition: all 0.3s linear;
        

        img {
            text-align: center;
            width: 100%;
        }
    }

    .slick-list {
        overflow: hidden;
        padding: 0 !important;
       
        @include media("<=810px") {
            padding: 0 50px !important;
        }
                @include media("<=430px") {
                    padding: 0 30px !important;
                }
    }
        .slick-track {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 32px;
            @include media("<=810px") {
                column-gap: 30px;
            }
            @include media("<=430px") {
                column-gap: 20px;
            }
        }
    
        .slider-item {
           max-width: 603px;
            width: 100%;
    
            @include media("<=430px") {
              
            }
            img {
                width: 100%;
            }
        }

}







.reviews {
    width: 100%;
    overflow: hidden;
padding: 316px 0 65px;
    position: relative;
    @include media("<=1440px") {
      padding-top: 280px;
    }
    &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 1867px;
            height: 1867px;
            border-radius: 50%;
            background: radial-gradient(50% 50% at 50% 50%, #0024DE 0%, rgba(82, 198, 238, 0.00) 100%);
    
            @include media("<=810px") {
                display: none;
            }
        }
}


.reviews__slider {
    margin-top: 46px;
    position: relative;
    margin: 0 auto;
    z-index: 3;
    width: 100%;
    max-width: 1147px;
    padding-bottom: 60px;
    height: 382px;
    @include media("<=1280px") {}

    @include media("<=1024px") {}

    @include media("<=630px") {
        height: 518px;
    }

    @include media("<=430px") {
    height: 478px;
    }

    @include media("<=320px") {}

    .slick-list {
        height: 322px !important;
        padding: 0 !important;
        overflow: hidden;
        // margin: 0 -250px;
        @include media("<=630px") {
            height: 458px !important; 
        }

        @include media("<=430px") {
        height: 418px !important;

        }
    }

    .slick-track {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        row-gap: 9px;
        @include media("<=810px") {
            
        }
    }

    .reviews__item {
       // padding: 58px 29px 0 29px;
        width: 100%;
        min-height: 152px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        @include media("<=1080px") {}

        @include media("<=630px") {
           min-height: 220px;

        }
                @include media("<=430px") {
                    min-height: 200px;
                }
    }

    .reviews__title {
        margin-bottom: 13px;
        width: 100%;
        position: relative;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        color: #FAFF00;
        z-index: 5;
        @include media("<=430px") {
            font-size: 24px;
        }

    }

    .reviews__text {
        font-size: 20px;
        line-height: 1.2;
        font-weight: 700;
        color: #fff;
        text-align: center;

        @include media("<=430px") {
            font-size: 16px;
        }
    }

    .slick-slide {
        position: relative;
        cursor: pointer;
        transition: all 0.3s linear;
    }


        
}

.slick-arrow {
    position: absolute;
    top: auto;
    bottom: 0;
    z-index: 10;
    font-size: 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 100%;
    max-width: 40px;
    max-height: 40px;
    height: 100%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s linear;

    &:hover {
        filter: drop-shadow(0px 2px 2px #ec97bd);
    }

    @include media("<=810px") {}

    @include media("<=430px") {}

    @include media("<=375px") {}

    @include media("<=320px") {
        max-width: 40px;
    }

    &.slick-prev {
        display: none;

        @include media("<=810px") {}

        @include media("<=430px") {}

        @include media("<=320px") {}
    }

    &.slick-next {

        background-image: url(../images/next.png);
        left: 50%;
        transform: translateX(-50%);

        @include media("<=810px") {}

        @include media("<=430px") {}

        @include media("<=320px") {}
    }
}

.reviews__pic {
    position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
    @include media("<=1440px") {
        width: auto;
    }
}


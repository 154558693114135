.footer {
  padding: 53px 0;
  text-align: center;
  position: relative;
  overflow-x: clip;
  @include media("<=810px") {
    padding: 40px 0;
    
  }
  @include media("<=430px") {
   padding: 30px 0;
  }
  
}

.footer-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media("<=810px") {
    flex-direction: column;
    row-gap: 25px;
  }
}

.footer__nav {
  display: flex;
  flex-direction: column;
    row-gap: 30px;
  @include media("<=810px") {
    
  }
}

.footer-link {
  color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
    text-align: left;
    font-size: 24px;
    font-weight: 900;
    @include media("<=810px") {
      font-size: 18px;
      
      text-align: center;
    }
  
    @include media("<=430px") {
  
     
     
    }
  
    &:hover {
      text-decoration: underline;
    }
  
  
}

.copyright {
  font-size: 12px;

  @include media("<=810px") {
    order: 3;
    
  }
}


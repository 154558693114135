.hero {
  overflow-x: clip;
  position: relative;
  background-image: url(../images/hero-bcg.png);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 111px 0;
  position: relative;
  z-index: 3;
  @include media("<=1280px") {
    padding: 100px 0;
  }
  @include media("<=1080px") {
    padding-bottom: 70px;
  }
  @include media("<=810px") {
    padding-top: 150px;
    padding-bottom: 180px;
    background-image: url(../images/hero-mob.png);
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;
  }
    @include media("<=430px") {
      padding: 130px 0 140px;
    }
    
}


.hero__inner {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 5;
  @include media("<=1280px") {
    
  }
  @include media("<=1080px") {
   
  }
  @include media("<=810px") {
  
    flex-direction: column;
    row-gap: 30px;
  }
  @include media("<=430px") {
    
  }
}


.hero__title {
 
  max-width: 1150px;
  width: 100%;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  color: transparent;
    font-size: 0;
    position: relative;
    z-index: 5;
    margin-bottom: 25px;
    transform: translate(0px, 50%) scale(0.6);
      opacity: 0.4;
      transition: all 0.8s ease 0s;
      @include media("<=1440px") {
        max-width: 1000px;
      }
  @include media("<=1280px") {
    max-width: 800px;
  }
    @include media("<=1080px") {
   max-width: 600px;
    
    }
  @include media("<=810px") {
    margin-bottom: 15px;
  }
  @include media("<=430px") {
   
    width: 95%;
    }

  @include media("<=330px") {
    
  }
  img {
    width: 100%;
    display: block;
  }
    &._active {
      transform: translate(0px, 0px) scale(1);
      opacity: 1;
    }
}

.link {
  flex: 0 1 100%;
  display: block;
  position: relative;
  z-index: 5;
  max-width: 256px;
  width: 100%;
  margin: 0 auto;
  transition: 0.4s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
  &:hover {
    filter: drop-shadow(0 8px 15px #FAFF00);
  }
  img {
    width: 100%;
    display: block;
  }
  @include media("<=1080px") {
   
  }
  @include media("<=tablet") {
    max-width: 220px;
  }
  @include media("<=430px") {
    max-width: 200px;
    
    position: relative;
    z-index: 3;
  }
}


.info {
    flex: 0 1 100%;
    width: 100%;
    position: relative;
    overflow-x: clip;
    height: 388px;
      background: #35008C;
      padding: 0 15px;
    @include media("<=1080px") {
    
    }
        @include media("<=810px") {
         min-height: 388px;
        }
                @include media("<=430px") {
                  min-height: 320px;
                }
 .section__title {
  text-align: right;
 }   
        
}

.info__inner {
    height: 100%;
    padding-top: 65px;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      z-index: 6;
      padding-left: 9.8vw;
      @include media("<=1280px") {
          padding-left: 0;
          justify-content: center;
        }
  @include media("<=1080px") {
    column-gap: 20px;
    
  }
    @include media("<=810px") {
        flex-direction: column;
        align-items: center;
        row-gap: 30px;
    }
        @include media("<=430px") {
          padding-top: 40px;
        }
     
}

.info__content {
    flex: 0 1 100%;
    max-width: 508px;
    position: relative;
    z-index: 3;
    @include media("<=810px") {
      
    }
}

.info__text {
  font-size: 20px;
    font-size: 500;
    z-index: 2;
    line-height: 1.1;
    width: 100%;
    text-align: justify;
    padding-top: 65px;
    @include media("<=1280px") {
        padding-top: 60px;
      }
    
      @include media("<=1080px") {
        font-size: 18px;
        padding-top: 40px;
      }
        @include media("<=430px") {
          width: 100%;
          font-size: 16px;
        }
}













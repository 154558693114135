.header {
  width: 100%;
 position: absolute;
 top: 0;
 left: 0;
 z-index: 5;
  padding: 34px 0 59px;
background: linear-gradient(180deg, #35008C 0%, rgba(104, 173, 249, 0.00) 100%);
  @include media("<=810px") {
    height: 100px;
    position: absolute;
    z-index: 10;
  }
  @include media("<=430px") {
    height: 80px;
  }
}

.header__inner {
  position: relative;
z-index: 15;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media("<=810px") {
    background-color: transparent;
  }
}

.header__nav {
  column-gap: 15px;
  max-width: 972px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 50;
  @include media("<=810px") {
    display: none;

    &.active {
      max-width: 100%;
      display: flex;
      position: fixed;
      height: 40%;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      border-radius: 0px 0px 30px 30px;
      background: #35008C;
      z-index: 20;
      margin-right: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: space-between;
      align-items: center;
      opacity: 0.97;
      
      .header__link {
        padding: 20px;
        margin-left: 0;
        @include media("<=430px") {
          padding: 15px;
        }
      }
    }
  }
}

.header__link {
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
color: #fff;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  @include media("<=810px") {
   color: #fff;
   text-align: center;
  }
@include media("<=430px") {
  font-size: 18px;
}
  &:hover {
    color: #ebff00;
  }
  
}

